// import React from 'react'
// import Layout from './Layout'
// import imghouse1 from "../assets/images/img1.png";
// import edit from "../assets/images/edit.svg"
// import del from "../assets/images/del.svg"
// import btnarrow from "../assets/images/btn-arrow.svg";
// import { Link } from 'react-router-dom';

// const Cart = () => {
//     return (
//         <>
//             <Layout>
//                 <div className='container'>
//                     <div className="top-heading mt-5">
//                         <h2>Cart</h2>
//                     </div>
//                 </div>
//                 <section className='carts '>
//                     <div className='container'>
//                         <div className='row'>
//                             <div className='col-lg-12 mx-auto'>
//                                 <div className='review-wrap'>
//                                     <div className="wraps">
//                                         <div className="img-h">
//                                             <img src={imghouse1} />
//                                             <div className="sociali-btn">

//                                                 <div className="fav">
//                                                     <img src={edit} />
//                                                 </div>
//                                                 <div className="fav">
//                                                     <img src={del} />
//                                                 </div>
//                                             </div>
//                                         </div>

//                                     </div>
//                                     <div className='cart-content'>
//                                         <div className='more-detail'>
//                                             <h4>Plan 135215GRA 3-Bed Barndominium-Style House Plan Under 1900 Square Feet with Extra-Deep 2-Car Garage</h4>
//                                             <div className='fal-add-cart'>
//                                                 <div className='sqrt'>
//                                                     <h5>2369</h5>
//                                                     <span>Sq. Ft.</span>

//                                                 </div>
//                                                 <hr></hr>
//                                                 <div className='bed'>
//                                                     <h5>3</h5>
//                                                     <span>Bed</span>

//                                                 </div>
//                                                 <hr></hr>
//                                                 <div className='bed'>
//                                                     <h5>3</h5>
//                                                     <span>Bath</span>

//                                                 </div>
//                                                 <hr></hr>
//                                                 <div className='story'>
//                                                     <h5>1</h5>
//                                                     <span>Story</span>

//                                                 </div>
//                                                 <hr></hr>
//                                                 <div className='cars'>
//                                                     <h5>2</h5>
//                                                     <span>Cars</span>

//                                                 </div>
//                                             </div>
//                                             <p className='sbuild'><strong>PDF - Single-Build:</strong> All orders are forwarded to our designers for fulfillment Monday-Friday.</p>
//                                             <p className='sbuild'><strong>IMPORTANT NOTE: </strong>If your order includes modifications, this delivery timeline is not applicable.</p>
//                                         </div>
//                                         <hr className='divider'></hr>
//                                         <div className='subtotal'>
//                                             <div className='plan'>
//                                                 <h5>Plan 135215GRA </h5>
//                                                 <div className='pdf'>
//                                                     <span>PDF - Single - Build</span>
//                                                     <strong>$25</strong>
//                                                 </div>
//                                             </div>
//                                             <hr></hr>
//                                             <div className='plan'>
//                                                 <h5>Plan 135215GRA  </h5>
//                                                 <div className='pdf'>
//                                                     <span>PDF - Single - Build</span>
//                                                     <strong>$25</strong>
//                                                 </div>
//                                             </div>
//                                             <hr className='mt-5'></hr>
//                                             <div className='cart-total'>
//                                                 <div className='tax'>
//                                                     <span>Tax</span>
//                                                     <strong>$25</strong>
//                                                 </div>
//                                                 <div className='tax'>
//                                                     <span>Subtotal </span>
//                                                     <strong>$25</strong>
//                                                 </div>
//                                                 <div className='tax final-total'>
//                                                     <span>Total </span>
//                                                     <strong>$8524</strong>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="btm-butn text-center">
//                             <Link to={'/checkout'} className='custom-link'>
//                             <button type="button" class="btn btn-primary ">
//                                 Proceed To Checkout
//                                 <img src={btnarrow} />
//                             </button>
//                             </Link>
//                         </div>
//                     </div>
//                 </section>
//             </Layout>
//         </>
//     )
// }

// export default Cart








import React, { useEffect, useState } from "react";
import Layout from './Layout'
import imghouse1 from "../assets/images/img1.png";
import edit from "../assets/images/edit.svg"
import del from "../assets/images/del.svg"
import btnarrow from "../assets/images/btn-arrow.svg";
import { Link } from 'react-router-dom';
import { deleteCartItem, getCart } from "../service/apiService";
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const navigate = useNavigate();

    const [cartData, setCartData] = useState();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    useEffect(() => {

        fetchCart();
    }, []);
    const fetchCart = async () => {
        try {
            const data = await getCart();
            setCartData(data);
            console.log(data.data);
        } catch (error) {
            console.error("Failed to fetch cart:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteCartItem(deleteId);
            await fetchCart();
            closeModal();
        } catch (error) {
            console.error("Failed to delete cart item:", error);
        }
    };

    const openModal = (id) => {
        setDeleteId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDeleteId(null);
    };

    if (loading) {
        return <p>Loading cart data...</p>;
    }
    const handleCheckout = (cartId) => {
        navigate('/checkout', { state: { cartId } });
    };
    return (
        <Layout>
            <div className='container'>
                <div className="top-heading mt-5">
                    <h2>Cart</h2>
                </div>
            </div>
            <section className='carts '>
                <div className='container'>
                    <div className='row'>
                        {cartData?.data?.map((item) => (
                            <div className='col-lg-12 mx-auto' key={item._id}>
                                <div className='review-wrap'>
                                    <div className="wraps">
                                        <div className="img-h">
                                            <img style={{ height: '250px', width: '350px' }} src={item?.property_id?.main_images?.[0] || imghouse1} alt="Property" />
                                            <div className="sociali-btn">
                                                {/* <div className="fav">
                                                    <img src={edit} alt="Edit" />
                                                </div> */}
                                                <div className="fav" onClick={() => openModal(item._id)}>
                                                    <img src={del} alt="Delete" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cart-content'>
                                        <div className='more-detail'>
                                            <h4>{item.name}</h4>
                                            <div className='fal-add-cart'>
                                                <div className='sqrt'>
                                                    <h5>{item?.property_id?.sq_ft}</h5>
                                                    <span>Sq. Ft.</span>
                                                </div>
                                                <hr />
                                                <div className='bed'>
                                                    <h5>{item?.property_id?.beds}</h5>
                                                    <span>Bed</span>
                                                </div>
                                                <hr />
                                                <div className='bed'>
                                                    <h5>{item?.property_id?.baths}</h5>
                                                    <span>Bath</span>
                                                </div>
                                                <hr />
                                                <div className='story'>
                                                    <h5>{item?.property_id?.story}</h5>
                                                    <span>Story</span>
                                                </div>
                                                <hr />
                                                <div className='cars'>
                                                    <h5>{item?.property_id?.no_of_vehicles}</h5>
                                                    <span>Cars</span>
                                                </div>
                                            </div>
                                            <p className='sbuild'><strong>Plan Number:</strong> {item?.property_id?.plan_number}</p>
                                            <p className='sbuild'><strong>Price:</strong> ${item?.property_id?.price}</p>
                                        </div>
                                        <hr className='divider' />
                                        <div className='subtotal'>
                                            <div className='plan'>
                                                <h5>{item.plan_number}</h5>
                                                <div className='pdf'>
                                                    <span>PDF - Single - Build</span>
                                                    <strong>${item?.property_id?.initial_discount || 0}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btm-butn text-center">
                                <div className="btm-butn text-center">
            <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleCheckout(item)} // Pass the cartId dynamically here
            >
                Proceed To Checkout
                <img src={btnarrow} alt="Arrow" />
            </button>
        </div>
                    </div>  
                            </div>
                        ))}
                    </div>
                
                </div>
            </section>

                 {/* Delete Confirmation Modal */}
                 {isModalOpen && (
                <div className="modal-overlay">
                <div className="delete-modal-cart" >

               
                    <div className="modal-content">
                        <h4>Confirm Deletion</h4>
                        <p>Are you sure you want to delete this item?</p>
                        <div className="modal-actions">
                            <button className="btn btn-secondary" onClick={closeModal}>
                                Cancel
                            </button>
                            <button className="btn btn-danger" onClick={handleDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Cart;