import React, { useState, useEffect, } from 'react';
import '../assets/css/style.css';
import accountpic from '../assets/images/account-png.png';
import edit_ico from '../assets/images/edit-ico.png';
import Layout from './Layout';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import imghouse1 from "../assets/images/img1.png";
import exclusive from "../assets/images/exclusive.svg";
import youtubr from "../assets/images/youtubr.svg";
import fav from "../assets/images/fav.svg";
import { getUserOrders, getUserProfile, updateUserProfile, uploadImage } from '../service/apiService';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getFavourites } from '../redux/reducers/favourites';
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Myaccount() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [orders, setOrders] = useState([]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [accountPic, setAccountPic] = useState(accountpic); // Default profile picture


  const dispatch = useDispatch();
  const { favouritesList, loadingfav, errorfav } = useSelector((state) => state.favourites);
  // Check if favouritesList has the data array
  const favoritesData = favouritesList?.data || [];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        const res = await getUserProfile(); // Replace with your actual API call
       const response = res.data
        console.log('-->',response)
        setProfile({
          fullName: response?.name || '',
          email: response?.email || '',
          phone: response?.phone || '',
        });
        setPreviewUrl(response?.profile_pic);

      } catch (err) {
        console.error('Failed to fetch user profile:', err);
        setError('Failed to load profile data.');
      } finally {
        setLoading(false);
      }
    };

    const fetchOrders = async () => {
      try {
        const res = await getUserOrders();
        setOrders(res.data); // Assuming `res.data` contains the list of orders
      } catch (err) {
        console.error('Failed to fetch orders:', err);
        setError('Failed to load orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();

    fetchUserProfile();
  }, []);
  const [profile, setProfile] = useState({
    fullName: '',
    email: '',
    phone: '',
    country_code: '',

  });
  useEffect(() => {
    dispatch(getFavourites());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     setSelectedImage(file);
//     setPreviewUrl(URL.createObjectURL(file));
//     handleImageUpload()
// };
const handleImageChange = async (e) => {
  const file = e.target.files[0];
  if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      
      // Call the upload function directly after selecting the file
      try {
          const  imageUrl  = await uploadImage(file); // Assuming API response contains `imageUrl`
          setProfile({ ...profile, image: imageUrl?.data?.file_url });
          setAccountPic(imageUrl?.data?.file_url);
          console.log('Upload', imageUrl);
          toast.success("Image uploaded successfully!");
      } catch (error) {
          toast.error("Failed to upload image. Please try again.");
      }
  } else {
      toast.error("No file selected. Please try again.");
  }
};

const handleImageUpload = async () => {

    if (!selectedImage) {
        toast.error("Please select an image to upload.");
        return;
    }
    try {
        const { imageUrl } = await uploadImage(selectedImage); // Assuming API response contains `imageUrl`
        setProfile({ ...profile, image: imageUrl });
        setAccountPic(imageUrl);
        toast.success("Image uploaded successfully!");
    } catch (error) {
        toast.error("Failed to upload image. Please try again.");
    }
};



 const handleLogout = () => {
    sessionStorage.clear();
    toast.success("Logged out successfully!");
    navigate("/SignIn");
    setShowLogoutModal(false); // Close the modal after logout
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false); // Close the modal without logging out
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const formData = new URLSearchParams();
      formData.append('name', profile.fullName);
      formData.append('email', profile.email);
      formData.append('phone_no', profile.phone);
      formData.append('country_code', profile.country_code);

         // Include the image URL if available
         if (profile.image) {
          formData.append('profile_pic', profile.image);
      }
      // console.log('FormData:', Object.fromEntries(formData.entries())); // Debug output
      const response = await updateUserProfile(formData);
      setMessage('Profile updated successfully!');
      toast.success('Profile updated successfully!');

      console.log('API Response:', response);
    } catch (error) {
      setMessage('Failed to update profile. Please try again.');
      toast.error('Failed to update profile. Please try again.');

    } finally {
      setLoading(false);
    }
  };
  const handleSelect = (eventKey) => {
    if (eventKey === 'contact1') {

setShowLogoutModal(true)
      
    }
};

  return (
    <Layout>
        <section className='my-account'>
                <div className='container'>
               <div className='acc-wrap'>
               <h3>My Account</h3>
                   {/* <div className='prof-img'>
                   <img src={accountpic} alt="user-img"/>
                    <div className='edit-ico'>
                        <img src={edit_ico} alt="edit-ico"/>
                    </div>
                   </div> */}
                   <div className='prof-img'>
                <img  style={{
    width: '160px',        // Set the width of the image
    height: '160px',       // Set the height of the image
    borderRadius: '50%',   // Make the image round
    objectFit: 'cover',    // Ensure the image covers the area without distortion
    border: '2px solid #ddd', // Optional: add a border around the image
    margin: '10px',        // Optional: add some space around the image
  }} src={ previewUrl ||  accountPic} alt="user-img" />
                <div className='edit-ico'>
                    <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>
                        <img src={edit_ico} alt="edit-ico" />
                    </label>
                    <input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                </div>
            </div>
         
               </div>
               <div className='my-acc-tabs'>
              <Tabs
              onSelect={handleSelect}
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
                >
                <Tab eventKey="home" title="Profile">
                {/* <Form className='acc-form'>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Full Name</Form.Label>
        <Form.Control type="text" placeholder="Enter full name" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control type="text" placeholder="+91 1234567890" />
      </Form.Group>
      <button type="button" class="btn btn-dark search dark"> <span>Save</span></button>
    </Form> */}

    {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  <Form className="acc-form" onSubmit={handleSubmit}>
    <Form.Group className="mb-3" controlId="fullName">
      <Form.Label>Full Name</Form.Label>
      <Form.Control
        type="text"
        name="fullName"
        value={profile.fullName}
        onChange={handleChange}
        placeholder="Enter full name"
      />
    </Form.Group>
    <Form.Group className="mb-3" controlId="email">
      <Form.Label>Email Address</Form.Label>
      <Form.Control
        type="email"
        name="email"
        value={profile.email}
        onChange={handleChange}
        placeholder="name@example.com"
      />
    </Form.Group>
    <Form.Group className="mb-3" controlId="phone">
      <Form.Label>Phone Number</Form.Label>
      <Form.Control
        type="text"
        name="phone"
        value={profile.phone}
        onChange={handleChange}
        placeholder="+91 1234567890"
      />
    </Form.Group>
    {/* <Form.Group className="mb-3" controlId="country_code">
      <Form.Label>Country Code</Form.Label>
      <Form.Control
        type="text"
        name="country_code"
        value={profile.country_code}
        onChange={handleChange}
        placeholder="+91"
      />
    </Form.Group> */}
                    <button type="submit" className="btn btn-dark search dark" disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                  </Form>
                )}

                </Tab>
                {/* <Tab eventKey="profile" title="My Orders">
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                         
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Tab> */}
                <Tab eventKey="profile" title="My Orders">
  <div className="row">
    {orders?.map((item, index) => {
      const property = item.property_id || {}; // Fallback in case property_id is null
      const mainImage = property.main_images?.[0] || "defaultImage.jpg"; // Use the first image or a placeholder
      return (
        <div key={index} className="col-lg-4 d-flex justify-content-center">
          <div className="wrap">
            <div className="img-h">
              <img src={mainImage} alt={property.name || "Property Image"} />
              <div className="sociali-btn">
                <div className="youtube">
                  <img src={youtubr} alt="YouTube Icon" />
                </div>
              </div>
            </div>
            <div className="ex-tag">
              <img src={exclusive} alt="Exclusive Tag" />
            </div>
            <div className="img-content">
              <div className="h-details">
                <div className="area">
                  <strong>{property.sq_ft || "N/A"}</strong>
                  <p className="mb-0">Sq. Ft.</p>
                </div>
                <hr />
                <div className="area">
                  <strong>{property.beds || "N/A"}</strong>
                  <p className="mb-0">Bed</p>
                </div>
                <hr />
                <div className="area">
                  <strong>{property.baths || "N/A"}</strong>
                  <p className="mb-0">Bath</p>
                </div>
                <hr />
                <div className="area">
                  <strong>{property.story || "N/A"}</strong>
                  <p className="mb-0">Story</p>
                </div>
                <hr />
                <div className="area">
                  <strong>{property.cars || "N/A"}</strong>
                  <p className="mb-0">Cars</p>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <div className="pln-no">
                  <span>Plan Number</span>
                  <h2>{property.plan_number || "N/A"}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </div>
</Tab>

                <Tab eventKey="contact" title="My favourites" >
                <div className='row'>
              {favoritesData.map((favItem, index) => {
                const property = favItem.property_id || {};
                return (
                  <div key={index} className='col-lg-4 d-flex justify-content-center'>
                    <div className="wrap">
                      <div className="img-h pt-0">
                        {/* <img  src={ favItem?.property_id?.main_images?.[0] || imghouse1} alt="favorite item" /> */}
                        <img 
  src={favItem?.property_id?.main_images?.[0] || imghouse1} 
  alt="favorite item" 
  style={{ width: '100%', height: '250px' }} 
/>
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} alt="YouTube" />
                          </div>
                          <div className="fav">
                            <img src={fav} alt="Favorite" />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} alt="Exclusive" />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>{property.sq_ft || 'N/A'}</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.beds || 'N/A'}</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.baths || 'N/A'}</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.story || 'N/A'}</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.cars || 'N/A'}</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#{property.plan_number || 'N/A'}</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">${property.price || 'N/A'}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
                </Tab>
                <Tab  eventKey="contact1" title="Logout" >
                    
                </Tab>
                </Tabs>
               </div>
                </div>

  
  {/* Logout Confirmation Modal */}
  <Modal show={showLogoutModal} onHide={handleCancelLogout} centered>
        <Modal.Header closeButton>
          <Modal.Title >Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelLogout}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

        </section>
  </Layout>
  )
}

export default Myaccount