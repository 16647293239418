// import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { propertyByPlanNumber } from '../redux/actions/PropertyAction';
// import { toast } from 'react-toastify';

// const Footer = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [planNumState, setPlanNumState] = useState("")


//   const propertyPlan = async () => {
//     await dispatch(propertyByPlanNumber({ plan_number: planNumState })).then((res) => {
//       if (res?.payload?.status === 200) {
//         toast.success(res?.payload?.message)
//         navigate(`/propertyList/${res?.payload?.data?._id}`)
//       } else {
//         toast.error(res?.payload?.message)
//       }
//     })
//   }

//   console.log("planNumState0", planNumState)

//   return (
//     <>
//       <footer>
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-4 d-flex flex-column justify-content-between">
//               <div className="looking-plan">
//                 <h3>Looking For Plan?</h3>
//                 <div className="mb-3 mt-4">
//                   <input type="text" className="form-control" placeholder="Search by plan #" onChange={(e) => setPlanNumState(e.target.value)} />
//                   <button type="button" className="btn btn-secondary" onClick={propertyPlan}>Search</button>
//                 </div>
//               </div>
//               <div className="logo-footer">
//                 <img src="./images/intro.png" alt="" />
//               </div>
//             </div>
//             <div className="col-lg-8">
//               <div className="row">
//                 <div className="col-lg-4 ">
//                   <h5>Find your plan</h5>
//                   <ul>
//                     <li><a href="">House Plan</a></li>
//                     <li><a href="">HOT Plans!</a></li>
//                     <li><a href="">New</a></li>
//                     <li><a href="">Styles</a></li>
//                     <li><a href="">Collections</a></li>
//                     <li><a href="">Client Builds</a></li>
//                     <li><a href="">Recently Sold</a></li>
//                     <li><a href="">Most Popular</a></li>
//                     <li><a href="">By US State</a></li>
//                     <li><a href="">By CA Province</a></li>
//                   </ul>
//                 </div>
//                 <div className="col-lg-4">
//                   <h5>About</h5>
//                   <ul>
//                     <li><a href="">Our Story</a></li>
//                     <li><a href="">Pricing</a></li>
//                     <li><a href="">Reviews</a></li>
//                     <li><a href="">Testimoials</a></li>
//                     <li><a href="">Copyright</a></li>
//                     <li><a href="">FAQ</a></li>
//                     <li><a href="">Contact Us</a></li>
//                     <li><a href="">blogs</a></li>
//                     <li><a href="">Return Policy </a></li>
//                     <li><a href="">Shipping Policy</a></li>
//                   </ul>
//                 </div>
//                 <div className="col-lg-4">
//                   <h5>Services</h5>
//                   <ul>
//                     <li><a href="">What’s Included</a></li>
//                     <li><a href="">Cost-to-Build Report</a></li>
//                     <li><a href="">Modifications</a></li>
//                     <li><a href="">Custom House Plans</a></li>
//                     <li><a href="">For Builder</a></li>
//                     <li><a href="">For Real Estate Agents</a></li>
//                     <li><a href="">Become an AD Designer</a></li>
//                     <li><a href="">Become an Affiliate</a></li>
//                     <li><a href="">Photo removal request </a></li>
//                     <li><a href="">Change to existing orders</a></li>
//                     <li><a href="">Home Products & Services</a></li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div className="contactus">
//                     <h5>Contact us</h5>
//                     <div className="details-others">
//                       <div className="call-info">
//                         <img src="./images/Component 1.svg" alt="" />
//                         <a href="tel: +234 8109824535" >+234 8109824535</a>

//                       </div>
//                       <div className="call-info">
//                         <img src="./images/mail_1.svg" alt="" />
//                         <a href="mailto: rholzsquad.studio@gmail.com" >rholzsquad.studio@gmail.com</a>

//                       </div>
//                       <div className="call-info">
//                         <img src="./images/where_to_vote.svg" alt="" />
//                         <p>NIGERIA / FLORIDA</p>

//                       </div>
//                     </div>
//                   </div>

//                 </div>
//                 <div className="col-lg-6">
//                   <div className="subscription">
//                     <h5>Subscription</h5>
//                     <div className="input-subs">
//                       <div className="mb-3">
//                         <input type="email" className="form-control" placeholder="E-mail" />
//                       </div>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div className="copyryt">
//                     <p>© 2024 — Copyright</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="social-media">
//                     <a href=""><img src="./images/item.svg" alt="" /></a>
//                     <a href=""><img src="./images/item_1.svg" alt="" /></a>
//                     <a href=""><img src="./images/item_2.svg" alt="" /></a>
//                     <a href=""><img src="./images/item_3.svg" alt="" /></a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// };

// export default Footer;



import React, { useState } from 'react';
import '../global.css'
// import item from '../assets/images/item.svg'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { toast, ToastContainer } from 'react-toastify';

import item from '../assets/images/item.svg'
import item1 from '../assets/images/item (1).svg'
import item2 from '../assets/images/item (2).svg'
import item3 from '../assets/images/item (3).svg'
import footerlogo from '../assets/images/intro.png'
import Component from '../assets/images/Component 1.svg'
import mail from '../assets/images/mail (1).svg'
import where_to_vote from '../assets/images/where_to_vote.svg'
import axios from 'axios';  // Ensure axios is installed and imported

function Footer() {
    const [planNumber, setPlanNumber] = useState('');  // State for search input
    const [properties, setProperties] = useState([]);  // State for search results
    const [error, setError] = useState(null);          // State for error handling
    const navigate = useNavigate();  // Initialize navigate

    const handleSearch = async () => {
        try {
          const baseUrl = process.env.REACT_APP_SQUAD_BASE_URL;
          const response = await axios.get(`${baseUrl}property`, {
            params: { plan_number: planNumber }
          });
          const data = response.data;
    
          if (data?.data && data?.data?._id) {
            // Success: Show success message and navigate
            toast.success('Property found! Navigating to details...');
            navigate(`/property/${data?.data?._id}`);
          } else {
            // Error: Show error message if no property is found
            toast.error('Property not found. Please check the plan number and try again.');
            setError('Property not found. Please check the plan number and try again.');
          }
        } catch (err) {
          console.error('Error fetching properties:', err);
          toast.error('Failed to fetch properties. Please try again.');
          setError('Failed to fetch properties. Please try again.');
        }
      };
  
    return (
    <footer>
    <div className="container">
        <div className="row">
            <div className="col-lg-4 d-flex flex-column justify-content-between">
                <div className="looking-plan">
                    <h3>Looking For Plan?</h3>
                    <div className="mb-3 mt-4">
                    <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Search by plan #" 
                  value={planNumber} 
                  onChange={(e) => setPlanNumber(e.target.value)}  // Update state on input change
                />
                <button 
                  type="button" 
                  className="btn btn-secondary" 
                  onClick={handleSearch}  // Trigger search on button click
                >Search</button>
                    </div>
                </div>
                <div className="logo-footer">
                    <img src={footerlogo} alt="" />
                </div>
            </div>
            <div className="col-lg-8">
                <div className="row">
                    <div className="col-lg-4 ">
                        <h5>Find your plan</h5>
                        <ul>
                            {/* <li><a href="">House Plan</a></li>
                            <li><a href="">HOT Plans!</a></li>
                            <li><a href="">New</a></li>
                            <li><a href="">Styles</a></li>
                            <li><a href="">Collections</a></li>
                            <li><a href="">Client Builds</a></li>
                            <li><a href="">Recently Sold</a></li>
                            <li><a href="">Most Popular</a></li>
                            <li><a href="">By US State</a></li>
                            <li><a href="">By CA Province</a></li> */}
                              <li><Link to="/home">House Plan</Link></li>
                  <li><Link to="/propertyList">HOT Plans!</Link></li>
                  <li><Link to="/propertyList">New</Link></li>
                  <li><Link to="/styles">Styles</Link></li>
                  <li><Link to="/favoritedetails">Collections</Link></li>
                  {/* <li><Link to="/client-builds">Client Builds</Link></li> */}
                  {/* <li><Link to="/recently-sold">Recently Sold</Link></li> */}
                  <li><Link to="/propertyList">Most Popular</Link></li>
                  {/* <li><Link to="/propertyList">By US State</Link></li> */}
                  {/* <li><Link to="/propertyList">By CA Province</Link></li> */}
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h5>About</h5>
                        <ul>
                            {/* <li><a href="">Our Story</a></li>
                            <li><a href="">Pricing</a></li>
                            <li><a href="">Reviews</a></li>
                            <li><a href="">Testimoials</a></li>
                            <li><a href="">Copyright</a></li>
                            <li><a href="">FAQ</a></li>
                            <li><a href="">Contact Us</a></li>
                            <li><a href="">blogs</a></li>
                            <li><a href="">Return Policy </a></li>
                            <li><a href="">Shipping Policy</a></li> */}
                             <li><Link to="/about-us">Our Story</Link></li>
                  <li><Link to="/pricing">Pricing</Link></li>
                  <li><Link to="/reviews">Reviews</Link></li>
                  <li><Link to="/testimonial">Testimonials</Link></li>
                  <li><Link to="/copyright">Copyright</Link></li>
                  <li><Link to="/faq">FAQ</Link></li>
                  <li><Link to="/contact-us">Contact Us</Link></li>
                  <li><Link to="/blog">Blogs</Link></li>
                  <li><Link to="/returnpolicy">Return Policy</Link></li>
                  {/* <li><Link to="/shipping-policy">Shipping Policy</Link></li> */}
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h5>Services</h5>
                        <ul>
                            {/* <li><a href="">What’s Included</a></li>
                            <li><a href="">Cost-to-Build Report</a></li>
                            <li><a href="">Modifications</a></li>
                            <li><a href="">Custom House Plans</a></li>
                            <li><a href="">For Builder</a></li>
                            <li><a href="">For Real Estate Agents</a></li>
                            <li><a href="">Become an AD Designer</a></li>
                            <li><a href="">Become an Affiliate</a></li>
                            <li><a href="">Photo removal request </a></li>
                            <li><a href="">Change to existing orders</a></li>
                            <li><a href="">Home Products & Services</a></li> */}
                            <li><Link to="/whats-included">What’s Included</Link></li>
                  <li><Link to="/cost-to-build">Cost-to-Build Report</Link></li>
                  <li><Link to="/modifications">Modifications</Link></li>
                  <li><Link to="/custom-house-plans">Custom House Plans</Link></li>
                  <li><Link to="/for-builder">For Builder</Link></li>
                  <li><a href="https://rholzsquad.com/builder/register" target="_blank" rel="noopener noreferrer">Become a Builder</a></li>

                  <li><Link to="/for-real-estate-agents">For Real Estate Agents</Link></li>
                  {/* <li><Link to="/become-ad-designer">Become an AD Designer</Link></li> */}
                  {/* <li><Link to="/become-affiliate">Become an Affiliate</Link></li> */}
                  {/* <li><Link to="/photo-removal-request">Photo Removal Request</Link></li> */}
                  <li><Link to="/returnpolicy">Change to Existing Orders</Link></li>
                  <li><Link to="/home-products-services">Home Products & Services</Link></li>

                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="contactus">
                            <h5>Contact us</h5>
                            <div className="details-others">
                                <div className="call-info">
                                    <img src={Component} alt=""/>
                                    <a href="tel: +234 8109824535" >+234 8109824535</a>
                                
                                </div>
                                <div className="call-info">
                                    <img src={mail} alt=""/>
                                    <a href="mailto: rholzsquad.studio@gmail.com" >rholzsquad.studio@gmail.com</a>
                                
                                </div>
                                <div className="call-info">
                                    <img src={where_to_vote} alt=""/>
                                  <p>NIGERIA / FLORIDA</p>
                                
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    {/* <div className="col-lg-6">
                        <div className="subscription">
                            <h5>Subscription</h5>
                            <div className="input-subs">
                                <div className="mb-3">
                                    <input type="email" className="form-control"  placeholder="E-mail" />
                                </div>
                            </div>
                        </div>
                       
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="copyryt">
                            <p>© 2024 — Copyright</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="social-media">
                            <a href=""><img src={item} alt="" /></a>
                            <a href=""><img src={item1} alt="" /></a>
                            <a href=""><img src={item2} alt="" /></a>
                            <a href=""><img src={item3} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer