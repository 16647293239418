import Api from "./Api"; // Adjust the path based on your file structure
import { toast } from 'react-toastify';
import axios  from 'axios';
const addToCart = async (formData) => {
    try {
        const response = await Api.post("/cart", formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const orderStatus = async (formData) => {
    try {
        const response = await Api.post("/order-status", formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

// apiService.js
// export const applyPromoCode = async (cartId, promoCodeId) => {
//     try {
//         const response = await Api.post('/apply-promocode', {
//             cart_id: cartId,
//             promocode_id: promoCodeId
//         });
//         return response;
//     } catch (error) {
//         throw new Error('Failed to apply promo code');
//     }
// };

export const applyPromoCode = async (cartId, promoCodeId) => {
    try {
        // Create a new URLSearchParams object for form data
        const formData = new URLSearchParams();
        formData.append('cart_id', cartId?._id);
        formData.append('promocode_id', promoCodeId);

        // Send the request with 'application/x-www-form-urlencoded' content type
        const response = await Api.post('/apply-promocode', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // Include any other necessary headers, e.g., Authorization
            },
        });
        console.log('response',response)
        return response;
    } catch (error) {

        console.error("Error applying promo code:", error);
        throw new Error('Failed to apply promo code');
    }
};

export const getCart = async () => {
    try {
        const response = await Api.get("/cart");
        return response.data;
    } catch (error) {
        console.error("Error fetching cart data:", error);
        throw error;
    }
};

export const deleteCartItem = async (id) => {
    try {
        const response = await Api.delete(`/cart/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting cart item:", error);
        throw error;
    }
};


// Fetch Related Properties
export const getRelatedProperties = async (planStyleId, propertyId, page = 1, limit = 10000) => {
    console.log('---api serivc->',planStyleId, propertyId)
    try {
        const response = await Api.get("/related-properties", {
            params: {
                plan_style_id: planStyleId,
                property_id: propertyId,
                // pagination: page,
                limit: limit,
            },
        });
        return response.data; // Adjust based on your API response structure
    } catch (error) {
        console.error("Error fetching related properties:", error);
        throw error;
    }
};

export const getPromoCodes = async () => {
    try {
        const response = await Api.get("/promocodes", {
            params: {
                limit: 1000,
            },
        });
        return response.data; // Adjust this based on your API response structure
    } catch (error) {
        console.error("Error fetching promo codes:", error);
        throw error;
    }
};
export const applyPayment = async (paymentData) => {
    const formData = new URLSearchParams();
    formData.append('payment_mode', paymentData.payment_mode);
    formData.append('cart_id', paymentData.cart_id);
    if (paymentData.promocode_id) {
        formData.append('promocode_id', paymentData.promocode_id);
    }

    try {
        const response = await Api.post('/order', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response; // Axios automatically parses the response, so no need for response.json()
    } catch (error) {
        console.error("Error applying payment:", error);
        throw error;
    }
};

export const  getUserProfile = async () => {
      try {
        const response = await Api.get('/profile'); // Replace with your API endpoint

        return response.data;
    } catch (error) {
        console.error("Error fetching cart data:", error);
        throw error;
    }
}
export const  getUserOrders = async () => {
    try {
      const response = await Api.get('/orders'); // Replace with your API endpoint

      return response.data;
  } catch (error) {
      console.error("Error fetching cart data:", error);
      throw error;
  }
}
  
export const updateUserProfile = async (data) => {
    try {
      const response = await Api.put('/profile', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  };
  
  export const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("file", imageFile);

    try {
        const response = await axios.post(`
https://rholzsquad.com/api/admin/upload-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data; // Assuming the response contains the image URL
    } catch (error) {
        console.error("Image upload failed:", error);
        throw error;
    }
};

export default addToCart ;