import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { orderStatus } from '../service/apiService';
import logo from "../assets/images/Logo.png"
import { toast } from "react-toastify";

// Load Stripe publishable key
const stripePromise = loadStripe('pk_test_51QD3gqC5m2qCeXrMOF59t9jMVidOGB4ovsL3uPBveHtA7ESaP9CEoNppOZIAv4Gx7PnTSw1iBDbxr49sPBOW4Kf700ERgvLKJz');

const CheckoutForm = () => {
    
    const location = useLocation();
    const navigate = useNavigate()
   
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    
    // Replace with your actual client secret for testing
    // const clientSecret = 'pi_3QRC15C5m2qCeXrM0swDDiVb_secret_kMl0O6eq67lRNFkQmCFCBNhZC';
    const clientSecret = location.state?.clientSecretId; // Retrieve clientSecret from navigation state
   console.log('clientSecretid is here ', clientSecret);
    if (!clientSecret) {
         <div>Error: Missing client secret. Please try again.</div>;
    }
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            setError('Stripe has not been fully initialized.');
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setError('CardElement not found.');
            setLoading(false);
            return;
        }

        try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            });

            if (error) {
                setError(error.message);
                setLoading(false);
                return;
            }


    try {
        const response = await orderStatus({
            intent_id: paymentIntent.id,
        });

        if (response.status === 200) {
            setSuccess(true);
            navigate('/cart')
          toast.success('Order Created Succesfully')
            
            console.log('Order status updated:', response.data);
        } else {
          toast.error('Failed to Update Order Status')

            setError('Failed to update order status.');
        }
    } catch (apiError) {
        console.error('Order status API error:', apiError);
        setError('An error occurred while updating the order status.');
    }
} catch (err) {
    setError('An unexpected error occurred.');
    console.error(err);
} finally {
    setLoading(false);
}
};

    return (
        <>
        <div className="checkout-container">
                    <div className="left-panel">
                        <div className="branding">
                            <img src={logo}  alt="Company Logo" className="logo" />
                            <h2>Rholzsquad, LLC</h2>
                        </div>
                        <p className="description">
                        Rholzsquad, LLC partners with Stripe for secure financial services.
                        </p>
                        <div className="footer">
                            <p>Powered by Stripe</p>
                            <a href="#">Help</a> | <a href="#">Privacy</a>
                            <p className="language">English (IN) ⌄</p>
                        </div>
                    </div>
        
                    <div className="right-panel">
                        <div className="card-container">
                            <h3>Rholzsquad Payments</h3>
                            <form onSubmit={handleSubmit}>
                                <CardElement className="stripe-card-element" />
                                <button
                                    type="submit"
                                    disabled={!stripe || loading || success}
                                    className="pay-button"
                                >
                                    {loading ? 'Processing...' : success ? 'Paid' : 'Pay Now'}
                                </button>
                                {error && <div className="error">{error}</div>}
                                {success && <div className="success">Payment Successful!</div>}
                            </form>
                        </div>
                    </div>
                </div>
        </>
    );
};

const TestCheckoutPage = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default TestCheckoutPage;
