// import React from 'react'
// import Layout from './Layout'
// import blogimg from "../assets/images/blogimg.png"
// import Pagination from 'react-bootstrap/Pagination';
// import { Link } from 'react-router-dom'

// const Blog = () => {
//   return (
//     <>
//       <Layout>
//         <div className='m-5 mobil'>
//         <section className='blog'>
//        <div className='container'>
//            <h2>Blogs</h2>
//            <div className='row'>
//                <div className='col-lg-4'>
//                     <div className='blog-cover'>
//                        <div>
//                        <img src={blogimg} />
//                      <div className='bg-content'>
//                             <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
//                         <strong>Bill Walsh leadership lessons</strong>
//                         <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
//                     <div className='read-more-blg'>
//                         <Link to="/blogdetails">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
// </svg></Link>
//                     </div>
//                      </div>
//                     </div>
//                     </div>
//                </div>
//                <div className='col-lg-4'>
//                     <div className='blog-cover'>
//                        <div>
//                        <img src={blogimg} />
//                      <div className='bg-content'>
//                             <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
//                         <strong>Bill Walsh leadership lessons</strong>
//                         <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
//                     <div className='read-more-blg'>
//                         <Link to="/blogdetails">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
// </svg></Link>
//                     </div>
//                      </div>
//                     </div>
//                     </div>
//                </div>
//                <div className='col-lg-4'>
//                     <div className='blog-cover'>
//                        <div>
//                        <img src={blogimg} />
//                      <div className='bg-content'>
//                             <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
//                         <strong>Bill Walsh leadership lessons</strong>
//                         <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
//                     <div className='read-more-blg'>
//                         <Link to="/blogdetails">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
// </svg></Link>
//                     </div>
//                      </div>
//                     </div>
//                     </div>
//                </div>
             
//            </div>
//           <div className='paging'>
//           <Pagination>
//       <Pagination.First />
//       <Pagination.Prev />
//       <Pagination.Item>{1}</Pagination.Item>
//       <Pagination.Ellipsis />

//       <Pagination.Item>{10}</Pagination.Item>
//       <Pagination.Item>{11}</Pagination.Item>
//       <Pagination.Item active>{12}</Pagination.Item>
//       <Pagination.Item>{13}</Pagination.Item>
//       <Pagination.Item disabled>{14}</Pagination.Item>

//       <Pagination.Ellipsis />
//       <Pagination.Item>{20}</Pagination.Item>
//       <Pagination.Next />
//       <Pagination.Last />
//     </Pagination>
//           </div>
//        </div>
//    </section>        </div>
//       </Layout>
//     </>
//   )
// }

// export default Blog



import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import blogimg from "../assets/images/blogimg.png"; // Use as a fallback if no image is provided
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Define the token
  const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2Njk4MDVhNWU5ZDM2ODgzYjY5NTY0NzYiLCJzY29wZSI6ImFkbWluX3Njb3BlIiwidG9rZW5fZ2VuX2F0IjoxNzM0MDE5NzY0Mzg1LCJpYXQiOjE3MzQwMTk3NjQsImV4cCI6MTc2NTU1NTc2NH0.YlQe4zhVI2DVS0XGXxNsi5Sd48SqledsqCRH14JVgIg';

  // Fetch blogs from API
  // useEffect(() => {
  //   const fetchBlogs = async () => {
  //     const token =
  //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2Njk4MDVhNWU5ZDM2ODgzYjY5NTY0NzYiLCJzY29wZSI6ImFkbWluX3Njb3BlIiwidG9rZW5fZ2VuX2F0IjoxNzM0MDE5NzY0Mzg1LCJpYXQiOjE3MzQwMTk3NjQsImV4cCI6MTc2NTU1NTc2NH0.YlQe4zhVI2DVS0XGXxNsi5Sd48SqledsqCRH14JVgIg";
    
  //     try {
  //       const response = await fetch("https://rholzsquad.com/api/admin/blog?limit=100", {
  //         method: "GET",
  //         headers: {
  //           "Authorization": `Bearer ${token}`, // Bearer token in the Authorization header
  //           "token": token, // Token included in a custom header
  //           "Content-Type": "application/json",
  //         },
  //       });
    
  //       if (!response.ok) {
  //         throw new Error(`Error: ${response.status}`);
  //       }
    
  //       const data = await response.json();
  //       console.log("Blogs fetched:", data);
  //       return data.blogs || [];
  //     } catch (error) {
  //       console.error("Failed to fetch blogs:", error.message);
  //     }
  //   };
    
    

  //   fetchBlogs();
  // }, []);
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://rholzsquad.com/api/admin/blog?limit=100", {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`, // Bearer token in the Authorization header
            "token": token, // Token included in a custom header
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
  
        const data = await response.json();
        console.log("Blogs fetched:", data);
  
        // Update state with fetched blogs and set loading to false
        setBlogs(data.data || []); // Assuming data.blogs contains the array of blogs
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch blogs:", error.message);
  
        // Stop loading even if there's an error
        setLoading(false);
      }
    };
  
    fetchBlogs();
  }, []);
  
  return (
    <>
      <Layout>
        <div className="m-5 mobil">
          <section className="blog">
            <div className="container">
              <h2>Blogs</h2>
              {loading ? (
                <p>Loading blogs...</p>
              ) : (
                <div className="row">
                  {blogs.map((blog) => (
                    <div className="col-lg-4" key={blog.id}>
                      <div className="blog-cover">
                        <div>
                          <img src={blog.image || blogimg} alt={blog.title} />
                          <div className="bg-content">
                            <div className="dte">
                              <p>{blog.author} <span>• {new Date(blog.createdAt).toLocaleDateString()}</span></p>
                            </div>
                            <strong>{blog.title||'Title' }</strong>
                            <p>{blog.description || 'No summary available'}</p>
                            <div className="read-more-blg">
                              <Link to={`/blogdetails`} state={{ blog }} > Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/* <div className="paging">
                <Pagination>
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Item>{11}</Pagination.Item>
                  <Pagination.Item active>{12}</Pagination.Item>
                  <Pagination.Item>{13}</Pagination.Item>
                  <Pagination.Item disabled>{14}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
              </div> */}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Blog;
